import { LinkBox } from "@chakra-ui/react";

export const ContactLinkBox = ({ children }: { children: React.ReactNode }) => {
  return (
    <LinkBox
      h="120px"
      w="300px"
      borderWidth={"1px"}
      borderRadius="lg"
      overflow="hidden"
    >
      {children}
    </LinkBox>
  );
};
