import { VStack } from "@chakra-ui/react";
import { TitleWithDivider } from "../../shared/TitleWithLine";
import { SmallContent } from "./SmallContent";

/**
 * 対応時期や曜日について 小さい画面用
 */
export const WorkingDayContentSmall = () => {
  return (
    <VStack align={"top"}>
      <TitleWithDivider title="9月〜4月" />

      <SmallContent
        name="かりゆし（八重山観光フェリー貨物船）"
        workingDays="月・火・水・木・金・※土"
        departure="石垣発 8:30"
      />

      <TitleWithDivider title="5月～8月" />

      <SmallContent
        name="ぱいかじ（安栄観光貨物船）"
        workingDays="月・水・金"
        departure="石垣発 8:30"
      />

      <SmallContent
        name="かりゆし（八重山観光フェリー貨物船）"
        workingDays="火・木・土"
        departure="石垣発 8:30"
      />
    </VStack>
  );
};
