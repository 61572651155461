import { Flex, Heading, VStack } from "@chakra-ui/react";
import SituationText from "./SituationText";

// 表示内容 1
const leftText = `①大原港の貨物船荷捌き場（片道約40kg）まで荷物を※2.5時間程かけて取りにいけない方

※西表島西部から大原港まで往復2時間＋貨物コンテナ（混載）より荷物を探すのに15分〜30分程。
◆港より回収後、一般の宅配荷物と同じように配達しますので急ぎの配達はいたしかねます。`;

// 表示内容 2
const rightText = `②大型家電をご運搬する際、知人に運搬手伝いのお願いや軽トラックを借りるのが面倒、または困難な方

★冷蔵庫は設置、梱包材の処分まで承ります。大型家電に関して配送スタッフの出勤状況によりご希望の日時にお届け出来ない場合があります。`;

const Situation = () => {
  return (
    <VStack>
      {/* タイトル */}
      <Heading fontSize="2xl" textAlign={"center"} marginBottom={4}>
        こんな方へ
      </Heading>

      <Flex direction={{ base: "column", md: "row" }} gap={8}>
        {/* テキスト1 */}
        <SituationText situation={leftText} />
        {/* テキスト2 */}
        <SituationText situation={rightText} />
      </Flex>
    </VStack>
  );
};

export default Situation;
