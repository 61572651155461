import {
  Box,
  Container,
  HStack,
  Heading,
  VStack,
  useMediaQuery,
  Text,
  Spacer,
  Link,
} from "@chakra-ui/react";
import { WorkingDayContentSmall } from "./small/WorkingDayContentSmall";
import { WorkingDayContentLarge } from "./large/WorkingDayContentLarge";

/**
 * 対応時期や曜日について
 */
const AvailableDateTime = () => {
  const [isLargerThan640] = useMediaQuery("(min-width: 640px)");
  return (
    <Box w={"100%"}>
      <Container maxW={"5xl"} px={{ base: 4, sm: 8, md: 16, lg: 0 }}>
        <VStack
          textAlign={"start"}
          align={"top"}
          py={{ base: 8, md: 16 }}
          fontSize={{ base: "lg", lg: "xl" }}
        >
          <Heading
            fontWeight={600}
            fontSize={{ base: "3xl", sm: "4xl", md: "6xl" }}
            lineHeight={"50%"}
            textAlign={"center"}
          >
            <Box paddingBottom={4}>
              <Heading as={"h1"} fontSize={"3xl"}>
                対応時期や曜日について
              </Heading>
            </Box>
          </Heading>
          {/* TODO: PC版のコンポーネントを作る */}
          {isLargerThan640 ? (
            <WorkingDayContentLarge />
          ) : (
            <WorkingDayContentSmall />
          )}
          <Box fontSize={{ base: "xs", md: "xl" }} mt={isLargerThan640 ? 8 : 2}>
            <HStack spacing={4}>
              <Spacer />
              <Text>ぱいかじ（安栄観光貨物事務所）</Text>
              {isLargerThan640 ? (
                <Text>TEL 0980-83-5891</Text>
              ) : (
                <Link href="tel:0980-83-5891">
                  <Text>TEL 0980-83-5891</Text>
                </Link>
              )}
            </HStack>
            <HStack spacing={4} mt={2}>
              <Spacer />
              <Text>かりゆし（八重山観光フェリー貨物船）</Text>
              {isLargerThan640 ? (
                <Text>TEL 0980-87-6310</Text>
              ) : (
                <Link href="tel:0980-87-6310">
                  <Text>TEL 0980-87-6310</Text>
                </Link>
              )}
            </HStack>
          </Box>
        </VStack>
      </Container>
    </Box>
  );
};

export default AvailableDateTime;
