import { Box, Heading, Stack, Image, Text, Center } from "@chakra-ui/react";

/**
 * タイトルを含むヘッダー
 */
export default function Header() {
  return (
    <Box
      w={"100%"}
      maxW={"5xl"}
      display={{ md: "flex" }}
      px={{ base: 4, sm: 8, md: 8, lg: 0, xl: 0 }}
    >
      {/* タイトル */}
      <Box w={"100%"}>
        <Stack
          textAlign={"start"}
          align={"top"}
          py={{ base: 16, sm: 16, md: 24 }}
        >
          <Heading
            as={"h2"}
            fontWeight={600}
            fontSize={{ base: "3xl", sm: "2xl", md: "4xl" }}
            lineHeight={"110%"}
          >
            <Text paddingBottom={4}>やまねこ宅配の</Text>
          </Heading>
          <Box>
            <Heading
              as={"h1"}
              fontSize={{ base: "4xl", sm: "4xl", md: "5xl", lg: "6xl" }}
              color={"#6DA58C"}
            >
              貨物配達サービス
            </Heading>
          </Box>
          <Text fontSize={{ base: "xl", sm: "2xl" }} color={"#A09C9C"} mt={4}>
            西表島の生活をより便利に！
            <br />
            冷蔵庫や洗濯機も配達致します。
            <br />
            ※サイズ上限あります
          </Text>
        </Stack>
      </Box>

      {/* 画像 */}
      <Box
        alignSelf={"center"}
        width={{ base: "100%", md: 250, lg: 400 }}
        flexShrink={0}
      >
        <Center>
          <Image
            src={
              "https://firebasestorage.googleapis.com/v0/b/yamaneko-takuhai.appspot.com/o/header_circle.png?alt=media&token=5514d58f-db9e-4645-a83d-4e71bfcfd860&_gl=1*1hd0o8w*_ga*MTk2NzkzODA0OS4xNjYwOTc1OTkw*_ga_CW55HF8NVT*MTY5ODkyNDAwOC41NS4xLjE2OTg5MjYwMTcuNDQuMC4w"
            }
          />
        </Center>
      </Box>
    </Box>
  );
}
