import { Text, Flex, Spacer } from "@chakra-ui/react";

type Props = {
  // 貨物の会社名
  name: string;
  // 稼働曜日
  workingDays: string;
  // 石垣発 hh:mm
  departure: string;
  // 注意メモ
  warning?: string;
};

/**
 * 画面が大きい端末用のコンポーネント
 */
export const LargeContent = ({
  name,
  workingDays,
  departure,
  warning,
}: Props) => {
  return (
    <>
      <Text>{name}</Text>
      <Flex>
        <Text>{workingDays}</Text>
        <Spacer />
        <Text pb={warning ? 4 : 8}>{departure}</Text>
      </Flex>

      {warning && (
        <Text pt={2} pb={8} fontSize={"sm"}>
          {warning}
        </Text>
      )}
    </>
  );
};
