import { Box, Center, Container, Heading, Stack, Text } from "@chakra-ui/react";

const Area = () => {
  return (
    <Box w={"100%"} backgroundColor={"#E0F5E8"}>
      <Center>
        <Container maxW={"5xl"} mx={{ base: 2, sm: 8, md: 8, lg: 0 }}>
          <Stack py={{ base: 8, md: 16 }}>
            <Heading
              as={"h1"}
              fontSize={"3xl"}
              paddingBottom={4}
              textAlign={"center"}
            >
              対応エリア
            </Heading>

            <Box fontSize={{ base: "lg", lg: "xl" }}>
              <Text as="p" marginTop="2" color="gray.700" align={"start"}>
                西表島の西部エリアが対象となります。
              </Text>
              <Text as="p" marginTop="2" color="gray.700" align={"start"}>
                船浦、上原、中野、住吉、浦内、祖納、干立、白浜、船浮
              </Text>
              <Text as="p" marginTop="2" color="gray.700" align={"start"}>
                ※東部地区（大原、大富、古見）は対応しておりません
              </Text>
            </Box>
          </Stack>
        </Container>
      </Center>
    </Box>
  );
};

export default Area;
