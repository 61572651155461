import { ChakraProvider, VStack, theme } from "@chakra-ui/react";
import Header from "./component/Header";
import Service from "./component/service/Service";
import Area from "./component/Area";
import Price from "./component/Price";
import Contact from "./component/contact/Contact";
import Footer from "./component/Footer";
import AvailableDateTime from "./component/working_day/WorkingDay";
import Cation from "./component/Cation";

export const App = () => (
  <ChakraProvider theme={theme}>
    <VStack spacing={8}>
      <Header />
      <Service />
      <Price />
      <Area />
      <AvailableDateTime />
      <Cation />
      <Contact />
      <Footer />
    </VStack>
  </ChakraProvider>
);
