import {
  Box,
  Container,
  Heading,
  VStack,
  UnorderedList,
  ListItem,
  Center,
} from "@chakra-ui/react";

/**
 * 注意事項
 */
const Contact = () => {
  return (
    <Box
      w={"100%"}
      backgroundColor={"#E0F5E8"}
      fontSize={{ base: "lg", lg: "xl" }}
    >
      <Center>
        <Container maxW={"5xl"} mx={{ base: 2, sm: 8, md: 8, lg: 0 }}>
          <VStack textAlign={"start"} align={"top"} py={{ base: 8, md: 16 }}>
            <Heading
              as={"h1"}
              fontSize={"3xl"}
              paddingBottom={4}
              textAlign={"center"}
            >
              注意事項
            </Heading>
            <UnorderedList spacing={2}>
              <ListItem>貨物船のみの対応となります</ListItem>
              <ListItem>
                サービスをご利用される場合は、事前にご連絡をお願いいたします
              </ListItem>
              <ListItem>
                対応時期、対応曜日がありますのでご確認をお願い致します
              </ListItem>
            </UnorderedList>
          </VStack>
        </Container>
      </Center>
    </Box>
  );
};

export default Contact;
