import { Text } from "@chakra-ui/react";

interface Props {
  situation: string;
}

export default function SituationText({ situation }: Props): JSX.Element {
  return (
    <Text
      flex="1"
      as="p"
      align={"start"}
      fontSize={{ base: "lg", lg: "xl" }}
      lineHeight={"150%"}
      whiteSpace="pre-wrap"
    >
      {situation}
    </Text>
  );
}
