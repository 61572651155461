import {
  Box,
  Container,
  Heading,
  VStack,
  Text,
  LinkOverlay,
  HStack,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { ContactLinkBox } from "./ContactLinkBox";
import { FaPhoneAlt } from "react-icons/fa";
import { GoMail } from "react-icons/go";
import { LineIcon } from "./LineIcon";
import { FaFax } from "react-icons/fa";
/**
 * お問い合わせ
 */
const Contact = () => {
  return (
    <Box w={"100%"}>
      <Container maxW={"5xl"} px={{ base: 4, sm: 8, md: 16, lg: 0 }}>
        <VStack align={"top"} py={{ base: 8, md: 16 }}>
          <Heading
            as={"h1"}
            textAlign={"center"}
            fontSize={"3xl"}
            paddingBottom={4}
          >
            お問い合わせ
          </Heading>

          <Wrap spacing="28px" justify="center">
            <WrapItem>
              <ContactLinkBox>
                <LinkOverlay href="mailto:yamanekotakuhai2019@gmail.com?subject=貨物配達サービスのお問い合わせ">
                  <HStack h="full" alignItems="center" p={4}>
                    <GoMail size={32} />
                    <Text fontSize={20}>メールでお問い合わせ</Text>
                  </HStack>
                </LinkOverlay>
              </ContactLinkBox>
            </WrapItem>

            <WrapItem>
              <ContactLinkBox>
                <VStack alignItems="self-start" p={4}>
                  <LinkOverlay href="tel:090-5195-2190">
                    <Text>お電話でお問い合わせ</Text>
                  </LinkOverlay>
                  <HStack align="center">
                    <FaPhoneAlt size={20} />
                    <Text fontSize={20}>090-5195-2190</Text>
                  </HStack>
                  <Text fontSize="12">受付時間 8:00〜18:00 定休日:日曜日</Text>
                </VStack>
              </ContactLinkBox>
            </WrapItem>

            <WrapItem>
              <ContactLinkBox>
                <LinkOverlay href="https://lin.ee/uwmP0Ra">
                  <HStack h="full" alignItems="center" p={4}>
                    <LineIcon />
                    <Text fontSize={20}>Lineでお問い合わせ</Text>
                  </HStack>
                </LinkOverlay>
              </ContactLinkBox>
            </WrapItem>

            <WrapItem>
              <ContactLinkBox>
                <VStack alignItems="self-start" p={4}>
                  <Text>Faxでお問い合わせ</Text>
                  <HStack align="center">
                    <FaFax size={20} />
                    <Text fontSize={24}>0980-87-7191</Text>
                  </HStack>
                </VStack>
              </ContactLinkBox>
            </WrapItem>
          </Wrap>
        </VStack>
      </Container>
    </Box>
  );
};

export default Contact;
