import { Divider, Heading, VStack } from "@chakra-ui/react";

/**
 * タイトルの下にhrタグで下線
 */
export function TitleWithDivider(props: { title: string }) {
  return (
    <VStack>
      <Heading
        w={"100%"}
        as="h3"
        fontSize={"2xl"}
        paddingBottom={2}
        alignSelf={"start"}
      >
        {props.title}
        <Divider paddingTop={4} borderColor={"black"} />
      </Heading>
    </VStack>
  );
}
